/* Braintree Styles */
.payment-method-braintree {
  position: relative; }

.payment-method-braintree .error {
  min-height: 1.5em;
  line-height: 1.5em;
  color: #f00;
  margin-top: -0.5em; }

.payment-method-braintree .braintree-field-wrap {
  display: inline-block;
  width: 100%;
  margin: 0;
  position: relative;
  min-height: 3.8125rem; }

.payment-method-braintree .braintree-field-wrap select {
  width: 100%; }

.payment-method-braintree .braintree-hosted-fields-iframe-container {
  padding: 1.25rem 0.625rem 0.625rem;
  width: 100%;
  height: 100%;
  border: 0.1rem solid #ced4da;
  border-radius: .1875rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff; }

.payment-method-braintree .braintree-hosted-fields-ph {
  position: absolute;
  left: 0;
  top: 0;
  height: 3.8125rem;
  width: 100%;
  padding: 1.5rem 2.25rem 0.375rem 0.75rem;
  cursor: default;
  color: var(--color-grey3);
  border: 0.0125rem solid #ced4da;
  border-radius: .1875rem; }

.payment-method-braintree .braintree-hosted-fields-ph + .form-control-label {
  top: 0.5rem; }

.payment-method-braintree .form-control-label {
  opacity: 1;
  color: var(--skin-secondary-color); }

.focused .payment-method-braintree .form-control-label {
  font-size: 0.6875rem;
  line-height: 0.875rem;
  color: var(--skin-secondary-color); }

.payment-method-braintree .form-control-label::before {
  color: var(--skin-secondary-color); }

.payment-method-braintree .braintree-text-input {
  width: 100%;
  height: 3.8125rem;
  position: absolute;
  left: 0;
  top: 0;
  border: 0.1rem solid #ced4da;
  border-radius: 0.1875rem;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.payment-method-braintree .braintree-text-input input {
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  padding: 1.25rem 0.625rem 0.625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  box-sizing: inherit;
  outline: none;
  color: var(--skin-primary-color);
  box-shadow: none;
  background-color: #fff; }

.focused .payment-method-braintree .braintree-text-input input {
  font-size: 0.6875rem;
  line-height: 0.875rem;
  color: var(--skin-secondary-color);
  padding: 1.25rem 0.625rem 0.625rem; }

.payment-method-braintree .braintree-text-input input.no-ph::-moz-placeholder {
  color: transparent; }

.payment-method-braintree .braintree-text-input input.no-ph:-ms-input-placeholder {
  color: transparent; }

.payment-method-braintree .braintree-text-input input.no-ph::placeholder {
  color: transparent; }

.payment-method-braintree .braintree-text-input input.no-ph::-moz-placeholder {
  color: transparent; }

.payment-method-braintree .braintree-text-input input.no-ph::-webkit-input-placeholder {
  color: transparent; }

.payment-method-braintree .braintree-hosted-fields-invalid {
  border-color: #ff0000; }

.braintree-make-default-card {
  border: none;
  background: transparent;
  color: #0070d2; }

.braintreeAddNewCard {
  border: none;
  background: transparent;
  color: #0070d2;
  text-decoration: underline; }

.braintreeCreditCardBtn {
  margin-bottom: 0.5rem; }

.js_braintree_accountgooglepay_button {
  margin-top: 0.5rem;
  text-align: center; }

.js_braintree_src_account_button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center; }

.braintree-loader-container {
  position: absolute;
  display: none;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  z-index: 990; }

.braintree-loader {
  position: absolute;
  color: #383838;
  font-size: 0.4rem;
  background: #fff;
  opacity: 0.5;
  margin: auto;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  -webkit-animation: load 1s infinite linear;
  animation: load 1s infinite linear;
  transform: translateZ(0); }

@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

.lpmProcessingMsg {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 60%;
  font-size: 1.5em; }

.braintree-account-add-credit-card-content p {
  text-align: center;
  margin-top: revert; }

.braintree-account-add-credit-card-content .braintree-hosted-fields-ph {
  display: none; }

.braintree-account-add-credit-card-content #braintreeFormErrorContainer {
  color: #f00;
  line-height: 1.5em;
  min-height: 1.5em; }

.braintree-account-add-credit-card-content #braintreeSrcFormErrorContainer {
  color: #f00;
  line-height: 1.5em;
  min-height: 1.5em; }

.js_braintree_creditCardFields .cardNumber {
  display: none; }

.used-creditcard-account-hide {
  display: none; }

.used-paypal-account-hide {
  display: none; }

.braintree-cart-paypal-buttons-wrap {
  position: relative;
  vertical-align: middle;
  text-align: right; }

.braitnree-cart-paypal-button {
  display: inline-block;
  min-height: 2.188em;
  width: 100%;
  position: relative; }

@media (max-width: 54.398rem) {
  .checkout-continue .braintree-cart-paypal-buttons-wrap .error {
    padding: 0; }
  .checkout-continue .braintree-cart-paypal-buttons-wrap .braitnree-cart-paypal-button {
    padding: 0; }
  .checkout-continue .braintree-cart-paypal-buttons-wrap {
    margin-bottom: 0.5rem; } }

#braintree3DSecureContainer {
  display: none; }

.braintree-static-paypal-button {
  display: flex;
  justify-content: center;
  cursor: pointer; }

.braintree_pdp_button {
  padding: 1.5em; }

@media screen and (min-width: 76.8rem) {
  #braintree3DSecureContainer {
    padding-left: 22%; } }

.braintree-cart-apple-buttons-wrap {
  text-align: right;
  padding-bottom: 0.938em;
  display: none;
  position: relative; }

.braintree-cart-apple-button-wrap {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  min-height: 2em; }

.braintree-billingpage-apple-buttons-wrap {
  text-align: left;
  padding-bottom: 0.938em;
  display: none;
  position: relative; }

.braintree-billingpage-apple-button-wrap {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  min-width: 15.625em;
  min-height: 2em; }

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    display: inline-block;
    width: 100%;
    -webkit-appearance: -apple-pay-button;
    cursor: pointer;
    border-radius: 0.9375rem;
    height: 2.875rem;
    border: 0.0125rem solid var(--skin-primary-color);
    background-color: var(--color-grey1); }
  .apple-pay-button-with-text > * {
    display: none; }
  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black; }
  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white; }
  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline; } }

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    --apple-pay-scale: 1;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    font-size: 0.75em;
    border-radius: 0.313em;
    padding: 0em;
    box-sizing: border-box;
    min-width: 12.5em;
    min-height: 2em;
    max-height: 4em;
    cursor: pointer; }
  .apple-pay-button-black-with-text {
    background-color: black;
    color: white; }
  .apple-pay-button-white-with-text {
    background-color: white;
    color: black; }
  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: 0.5rem solid black; }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black; }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white; }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white; }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(0.2rem * var(--apple-pay-scale)); }
  .apple-pay-button-with-text > .logo {
    width: calc(2.188em * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(0.125em * var(--apple-pay-scale));
    border: none; } }

.js_braintree_applepay_button_disabled {
  opacity: 0.4;
  cursor: default; }

@media (max-width: 54.398rem) {
  .checkout-continue .braintree-cart-apple-buttons-wrap .error {
    padding: 0; }
  .checkout-continue .braintree-cart-apple-buttons-wrap .braintree-cart-apple-button-wrap {
    padding: 0; }
  .checkout-continue .braintree-cart-apple-buttons-wrap .braintree-cart-apple-button {
    padding: 0; } }

.used-venmo-account-hide {
  display: none; }

.braintree-venmo-pay-button {
  width: 15rem;
  height: 6rem;
  background: url(../images/braintree_venmo_btn.png) 0 0/10rem no-repeat;
  cursor: pointer; }

.braintree_accountVenmoButton {
  width: 15rem;
  height: 6rem;
  background: url(../images/braintree_venmo_btn.png) 0 0/10rem no-repeat;
  cursor: pointer; }

.venmoUsername {
  color: #000000;
  line-height: 2.5em; }

#venmo-content .venmo-error {
  color: #f00;
  line-height: 1.5em;
  min-height: 1.5em; }

.venmo-braintree-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: #fff 50% 50% url(../images/ajax-loader.gif) no-repeat;
  z-index: 100;
  text-align: center; }

.lpmButton {
  border: 0.1rem solid black;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: none; }

.lpm-button-image {
  width: 10rem;
  height: 3.2rem; }

.braintree-cart-src-buttons-wrap {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.used-googlepay-account-hide {
  display: none; }

.card-body-bt:not(:last-child) {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.125); }

.remove-bt-payment {
  border: none;
  background: transparent;
  color: #0070d2;
  text-decoration: underline; }

.add-paypal-account,
.add-venmo-account {
  color: #0070d2;
  cursor: pointer;
  text-decoration: underline; }

.js_braintree_accountPaypalButton_wrapper .error,
.js_braintree_accountVenmoButton_wrapper .error {
  text-align: left;
  color: #f00;
  line-height: 1.5em;
  min-height: 1.5em; }

.custom-checkbox {
  text-align: left; }
